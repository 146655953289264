import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedContent, setSubtopics } from '../../Redux/Slice';
import { useParams } from 'react-router-dom';
const apiKey = process.env.REACT_APP_SECURE_KEY;

const Sidebar = () => {
  const params = useParams();
  const [activeSubtopic, setActiveSubtopic] = useState(null);
  const subTopics = useSelector((state) => state.data.subTopics);
  const [languageName, setLanguageName] = useState('');
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const dispatch = useDispatch();

  const fetchSubtopics = async (id) => {
    try {
      const response = await fetch(`${apiKey}/course/get_subtopic?id=${id}`);
      if (response.ok) {
        const data = await response.json();
        dispatch(setSubtopics(data.data[0].sub_topic_name));
        setLanguageName(data.data[0].language_name);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSubtopicDetails = async (id, subtopic_name) => {
    try {
      const apiData = await fetch(
        `${apiKey}/course/get_subtopic_details?id=${id}&subtopic_name=${subtopic_name}`
      );

      if (apiData.ok) {
        const data = await apiData.json();
        if (data.error === false && data.data.length > 0) {
          const selectedData = data.data[0];
          dispatch(
            setSelectedContent({
              _id: selectedData._id,
              topic_id: selectedData.topic_id,
              subtopic_name: selectedData.subtopic_name,
              code: selectedData.code,
              language_runner: selectedData.language_runner,
              description: selectedData.description,
              output: selectedData.output,
            })
          );
          setActiveSubtopic(subtopic_name);
          setIsSidebarCollapsed(true);
        } else {
          throw new Error('No data available for the selected subtopic');
        }
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchSubtopics(params.id);

    const handleSelectFirstSubtopic = (event) => {
      const { topicId } = event.detail;
      fetchSubtopics(topicId);
    };

    window.addEventListener('selectFirstSubtopic', handleSelectFirstSubtopic);

    return () => {
      window.removeEventListener('selectFirstSubtopic', handleSelectFirstSubtopic);
    };
  }, [params.id]);

  useEffect(() => {
    if (subTopics.length > 0) {
      fetchSubtopicDetails(params.id, subTopics[0]);
    }
  }, [subTopics]);

  const handleSidebarItemClick = (subtopic_name) => {
    fetchSubtopicDetails(params.id, subtopic_name);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <>
      <div className='navbar-sidebar sidebar_bg_deisgn'></div>
      <div
        className='language_name'
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#mysidebar'
          aria-controls='mysidebar'
          aria-expanded={!isSidebarCollapsed}
          aria-label='Toggle navigation'
          onClick={toggleSidebar}
        >
          <i className='fa fa-bars'></i>
        </button>
        <p>{languageName}</p>
      </div>

      <div className={`collapse vertical-menu ${isSidebarCollapsed ? 'collapsed' : 'show'}`} id='mysidebar'>
        {console.log(subTopics)}
        {Array.isArray(subTopics) && subTopics.length > 0 ? (
          subTopics.map((subTopic, index) => (
            <Link
              to=''
              key={index}
              className={activeSubtopic === subTopic ? 'active' : ''}
              onClick={() => handleSidebarItemClick(subTopic)}
            >
              {subTopics[index]}
            </Link>
          ))
        ) : (
          <p>No subtopics available</p>
        )}
      </div>
    </>
  );
};

export default Sidebar;
