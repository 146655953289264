import React, { useEffect, useState } from 'react';

const TermAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className='aboutus-container'>
      <div className='tabs'>
        
        <button className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
          Terms & condition
        </button>
       
      </div>
      <div className='tab-content'>
      
        {activeTab === 0 && (
          <div>
            <h4>
              <b>TERMS & CONDITIONS</b>
            </h4>
            Welcome to our Privacy Policy
            <br />
            Your privacy is critically important to us.
            <br />
            Codingveda is located at:
            <br />
            <br />
            codingveda <br />
            vidhyadhar nagar vidhyadhar nagar, jaipur
            <br />
            302023 - rajasthan , india
            <br />
            <br />
            It is codingveda policy to respect your privacy regarding any information we may collect while operating our
            website. This Privacy Policy applies to www.codingveda.com (hereinafter, "us", "we", or
            "www.codingveda.com"). We respect your privacy and are committed to protecting personally identifiable
            information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy")
            to explain what information may be collected on our Website, how we use this information, and under what
            circumstances we may disclose the information to third parties. This Privacy Policy applies only to
            information we collect through the Website and does not apply to our collection of information from other
            sources.
            <br />
            This Privacy Policy, together with the Terms of service posted on our Website, set forth the general rules
            and policies governing your use of our Website. Depending on your activities when visiting our Website, you
            may be required to agree to additional terms of service.
            <br />
            <br />
            <h5>Website Visitors</h5>
            <br />
            Like most website operators, codingveda collects non-personally-identifying information of the sort that web
            browsers and servers typically make available, such as the browser type, language preference, referring
            site, and the date and time of each visitor request.codingveda s purpose in collecting non-personally
            identifying information is to better understand how codingveda "s visitors use its website. From time to
            time, codingveda may release non-personally-identifying information in the aggregate, e.g., by publishing a
            report on trends in the usage of its website.
            <br />
            <br />
            codingveda also collects potentially personally-identifying information like Internet Protocol (IP)
            addresses for logged in users and for users leaving comments on http://www.codingveda.com blog posts.
            codingveda only discloses logged in user and commenter IP addresses under the same circumstances that it
            uses and discloses personally-identifying information as described below.
            <br />
            <br />
            <h5>Gathering of Personally-Identifying Information</h5>
            <br />
            Certain visitors to codingveda websites choose to interact with codingveda in ways that require codingveda
            to gather personally-identifying information. The amount and type of information that codingveda gathers
            depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at
            http://www.codingveda.com to provide a username and email address.
            <br />
            <br />
            <h5>Security</h5>
            <br />
            The security of your Personal Information is important to us, but remember that no method of transmission
            over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
            <br />
            <br />
            <h5>Advertisements</h5>
            <br />
            Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These
            cookies allow the ad server to recognize your computer each time they send you an online advertisement to
            compile information about you or others who use your computer. This information allows ad networks to, among
            other things, deliver targeted advertisements that they believe will be of most interest to you. This
            Privacy Policy covers the use of cookies by codingveda and does not cover the use of cookies by any
            advertisers.
            <br />
            <br />
            <h5>Links To External Sites</h5>
            <br />
            Our Service may contain links to external sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy
            and terms of service of every site you visit.
            <br />
            We have no control over, and assume no responsibility for the content, privacy policies or practices of any
            third party sites, products or services.
            <br />
            <br />
            <h5>codingveda uses Google AdWords for remarketing</h5>
            <br />
            codingveda uses the remarketing services to advertise on third party websites (including Google) to previous
            visitors to our site. It could mean that we advertise to previous visitors who haven't completed a task on
            our site, for example using the contact form to make an enquiry. This could be in the form of an
            advertisement on the Google search results page, or a site in the Google Display Network. Third-party
            vendors, including Google, use cookies to serve ads based on someone's past visits. Of course, any data
            collected will be used in accordance with our own privacy policy and Google's privacy policy.
            <br />
            You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you
            want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a
            browser plugin.
            <br />
            <br />
            <h5>Protection of Certain Personally-Identifying Information</h5>
            <br />
            codingveda discloses potentially personally-identifying and personally-identifying information only to those
            of its employees, contractors and affiliated organizations that (i) need to know that information in order
            to process it on codingveda behalf or to provide services available at codingveda website, and (ii) that
            have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations
            may be located outside of your home country; by using codingveda website, you consent to the transfer of
            such information to them. codingveda will not rent or sell potentially personally-identifying and
            personally-identifying information to anyone. Other than to its employees, contractors and affiliated
            organizations, as described above, codingveda discloses potentially personally-identifying and
            personally-identifying information only in response to a subpoena, court order or other governmental
            request, or when codingveda believes in good faith that disclosure is reasonably necessary to protect the
            property or rights of codingveda , third parties or the public at large.
            <br />
            If you are a registered user of http://www.codingveda.com and have supplied your email address, codingveda
            may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you
            up to date with what's going on with codingveda and our products. We primarily use our blog to communicate
            this type of information, so we expect to keep this type of email to a minimum. If you send us a request
            (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it
            in order to help us clarify or respond to your request or to help us support other users. codingveda takes
            all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction
            of potentially personally-identifying and personally-identifying information.
            <br />
            <br />
            <h5>Aggregated Statistics</h5>
            <br />
            codingveda may collect statistics about the behavior of visitors to its website. codingveda may display this
            information publicly or provide it to others. However, codingveda does not disclose your
            personally-identifying information.
            <br />
            <br />
            <h5>Affiliate Disclosure</h5>
            <br />
            This site uses affiliate links and does earn a commission from certain links. This does not affect your
            purchases or the price you may pay.
            <br />
            <br />
            <h5>Cookies</h5>
            <br />
            To enrich and perfect your online experience, codingveda uses "Cookies", similar technologies and services
            provided by others to display personalized content, appropriate advertising and store your preferences on
            your computer.
            <br />
            A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's
            browser provides to the website each time the visitor returns. codingveda uses cookies to help codingveda
            identify and track visitors, their usage of http://www.codingveda.com, and their website access preferences.
            codingveda visitors who do not wish to have cookies placed on their computers should set their browsers to
            refuse cookies before using codingveda websites, with the drawback that certain features of codingveda
            websites may not function properly without the aid of cookies.
            <br />
            By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and
            agree to codingveda use of cookies.
            <br />
            <br />
            <h5>E-commerce</h5>
            <br />
            Those who engage in transactions with codingveda – by purchasing codingveda services or products, are asked
            to provide additional information, including as necessary the personal and financial information required to
            process those transactions. In each case, codingveda collects such information only insofar as is necessary
            or appropriate to fulfill the purpose of the visitor's interaction with codingveda . codingveda does not
            disclose personally-identifying information other than as described below. And visitors can always refuse to
            supply personally-identifying information, with the caveat that it may prevent them from engaging in certain
            website-related activities.
            <br />
            <br />
            <h5>Privacy Policy Changes</h5>
            <br />
            Although most changes are likely to be minor, codingveda may change its Privacy Policy from time to time,
            and in codingveda sole discretion. codingveda encourages visitors to frequently check this page for any
            changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will
            constitute your acceptance of such change.
            <br />
            we are kept user information confidential.
          </div>
        )}

      </div>
    </div>
  );
};

export default TermAndCondition;
