import React, { useEffect } from 'react';
import { FaLock } from 'react-icons/fa';

const Course = () => {
  const handlePayment = () => {
    const options = {
      key: 'rzp_test_ZkLfnkiooM9YPg',
      amount: 1900, // Amount in paise (₹19)
      currency: 'INR',
      name: 'codingveda',
      description: 'Course Fee',
      image: './images/spoken_english.png',
      handler: function (response) {
        console.log(response);
        if (response.razorpay_payment_id) {
          window.location.href = '/Dashboard';
        }
      },
      prefill: {
        name: 'lokesh kumar rewar',
        email: 'lokeshrewar@gmail.com',
        contact: '95221133660',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='container'>
        <h2 className='text-center mt-5 mb-4'>Our Courses</h2>
        <div className='row'>
          <div className='col-md-4 mb-4'>
            <div className='card'>
              <img src='./images/spoken_english.png' className='card-img-top' alt='img' />
              <div className='card-body'>
                <h6 className='card-title'>Unlock Your Confidence with Fluent English!</h6>
                <ul>
                  <li>One time course fee</li>
                  <li>Batch Mentor "Hishita"</li>
                  <li>Practice based E-Notes will be provided in order to enhance spoken skills</li>
                </ul>
                <h6>
                  <b>₹ 19 /-</b>
                  <br />
                  Price <strike>₹ 999 /-</strike>
                </h6>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                  <button onClick={handlePayment} className='pay_now_btn'>
                    <FaLock />
                    &nbsp;&nbsp;&nbsp;Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Course;
