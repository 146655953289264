import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Editor from '@monaco-editor/react';
import { Link } from 'react-router-dom';

const Contentarea = ({ selectedContent }) => {
  const { subtopic_name, description, code, output, language_runner } = selectedContent;

  const copyCodeToClipboard = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success('Code copied to clipboard', {
          position: 'top-right',
        });
      })
      .catch((error) => {
        console.error('Unable to copy code: ', error);
        toast.error('Failed to copy code. Please try again.', {
          position: 'top-right',
        });
      });
  };

  return (
    <div>
      <ToastContainer />
      <div>
        <h4 className='programname'>{subtopic_name}</h4>
        <div dangerouslySetInnerHTML={{ __html: description }} style={{ padding: '7px' }} />
        <div className='codeEditor'>
          <div className='chead'>
            <h6>Code</h6>
            {language_runner && (
              <button className='copy_button'>
                <Link to={language_runner}>Try Yourself</Link>
              </button>
            )}
            <button className='copy_button' onClick={copyCodeToClipboard}>
              COPY
            </button>
          </div>
          <Editor
            theme='vs-dark'
            height='60vh'
            defaultLanguage='javascript'
            value={code}
            defaultValue={code}
            options={{ readOnly: true }}
          />
        </div>
        <br />
        <div className='codeEditor'>
          <div className='chead'>
            <h6>Output</h6>
          </div>
          <p className='codeBgc'>
            <div dangerouslySetInnerHTML={{ __html: output }} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contentarea;
